import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import withStyles from "@material-ui/core/styles/withStyles";
import {AppBar, createMuiTheme, Link, responsiveFontSizes, ThemeProvider, Toolbar} from '@material-ui/core';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const ColorButton = withStyles((theme) => ({
    root: {
        color: "#fff",
        backgroundColor: "#1b5e20",
        '&:hover': {
            backgroundColor: "#fff",
        },
        fontWeight: "bold",
        fontFamily: '"Segoe UI Emoji"',
    },
}))(Button);


const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
    },
    cardTextGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardMedia2: {
        paddingTop: '200%', // 16:9
    },

    cardContent: {
        flexGrow: 1,
    },
    main1: {
        backgroundColor: '#1b5e20',
    },
    main2: {
        backgroundColor: '#1b5e20',
        marginBottom: theme.spacing(2),
    },
    footer: {
        backgroundColor: '#343A40',
        marginTop: theme.spacing(8),
    },
    header: {
        marginBottom: theme.spacing(4),
        color: "#fff",
        fontWeight: "bold",
    },
    text1: {
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    }
}));

function Footer() {
    const classes = useStyles();
    return (

        <Typography variant="body2" color="#fff" align="center" className={classes.text1} >
            <br/>
            <br/>
            {'Google Play und das Google Play-Logo sind Marken von Google LLC.'}
            <br/> <br/>
            {'Copyright Klima Buddy '}
            {new Date().getFullYear()}
            {'. '}
            <Link color="inherit" href="/impressum">
                {' '}Impressum
            </Link>{'. '}

            <br/>
            <br/>
        </Typography>
    );
}

export default function Album() {
    const classes = useStyles();

    return (
        <React.Fragment>

            <ThemeProvider theme={theme}>
                <CssBaseline />

                <main className={classes.main1}>


                    <Container disableGutters={true} maxWidth="lg">
                        <Typography variant="h1" component="h1" gutterBottom className={classes.header} align="center" marginTop={5}>
                            <br/> Klima Buddy
                        </Typography>
                    </Container>

                    <Container className={classes.main2} maxWidth="lg" disableGutters={true}>


                        <Container className={classes.main2}  maxWidth="md" disableGutters={true}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h4" component="h3" gutterBottom className={classes.text1} align="center">
                                    Berechne den CO2 Fußabdruck deiner Reise mit dem Klima Buddy.
                                    <br/>                                     <br/>

                                </Typography>
                                <Typography variant="h6" component="h6" gutterBottom className={classes.text1} align="center">
                                    Mit dem Klima Buddy kannst du jetzt deinen persönlichen CO2 Fußabdruck deiner Reisen berechnen. Vergleiche die CO2 Emissionen, Reisedauer und Reisekosten von Zug, Auto, Flugzeug und Bus. Wie wirst du reisen?
                                    <br/><br/>

                                </Typography>

                                <Container className={classes.heroButtons} maxWidth="xs">
                                    <Grid container justify="center">
                                        <ButtonGroup   variant="contained"  >
                                            <ColorButton href="https://play.google.com/store/apps/details?id=com.carlschwein.servus&gl=DE" >
                                                <img
                                                    src="https://landingpage-2b2c4.firebaseapp.com/images/google-play-badge.png" width="50%" height="50%"
                                                ></img>
                                            </ColorButton>
                                        </ButtonGroup>
                                    </Grid>
                                </Container>
                                <br/>

                            </CardContent>
                        </Container>

                        <Container className={classes.cardGrid}  maxWidth="md">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={8} md={6}>
                                    <Card className={classes.card}>
                                        <CardMedia
                                            className={classes.cardMedia2}
                                            image="https://landingpage-2b2c4.firebaseapp.com/images/pic3.jpg"
                                            title="Image title"
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={8} md={6}>
                                    <Card className={classes.card}>
                                        <CardMedia
                                            className={classes.cardMedia2}
                                            image="https://landingpage-2b2c4.firebaseapp.com/images/pic1.jpg"
                                            title="Image title"
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </Container>
                    <Footer/>
                </main>
            </ThemeProvider>
        </React.Fragment>
    );
}
