import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {Link} from "@material-ui/core";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(0.5, 0, 0.5),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
    },
    cardTextGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardMedia2: {
        paddingTop: '200%', // 16:9
    },

    cardContent: {
        flexGrow: 1,
    },
    main1: {
        backgroundColor: '#1b5e20',
    },
    main2: {
        backgroundColor: '#1b5e20',
        marginBottom: theme.spacing(2),
    },
    footer: {
        backgroundColor: '#343A40',
        marginTop: theme.spacing(8),
    },
    header: {
        marginBottom: theme.spacing(4),
        color: "#fff",
        fontWeight: "bold",
    },
    text1: {
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    },
    text2: {
        color: "#fff",
        fontFamily: '"Segoe UI Emoji"',
    }
}));

function Footer() {
    const classes = useStyles();
    return (
        <Typography variant="body2" color="#fff" align="center" className={classes.text1} >
            <br/>
            <br/>
            {'Google Play und das Google Play-Logo sind Marken von Google LLC.'}
            <br/> <br/>
            {'Copyright Klima Buddy '}
            {new Date().getFullYear()}
            {'. '}
            <Link color="inherit" href="/impressum">
                {' '}Impressum
            </Link>{'. '}

            <br/>
            <br/>
        </Typography>
    );
}

export default function Impressum() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={theme}>

                <main className={classes.main1}>

                    <Container disableGutters={true}>
                        <Typography variant="h2" component="h2" gutterBottom className={classes.header} align="center" marginTop={5}>
                            Impressum
                        </Typography>
                    </Container>

                    <Container className={classes.main2} maxWidth="lg" disableGutters={true}>
                        <Container className={classes.main2}  maxWidth="md" disableGutters={true}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Angaben gemäß § 5 TMG
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    Samuel Grossmann <br/>
                                    Krakvitz 14B <br/>
                                    18581 Putbus <br/>
                                </Typography>


                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Kontakt
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    E-Mail: carlschwein@gmaail.com  <br/>
                                </Typography>

                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Haftung für Inhalte                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                                    forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                                    Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                                    Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                                </Typography>

                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Haftung für Links
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                                    verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                                    Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                                    waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                                    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                                    umgehend entfernen.
                                </Typography>

                                <Typography variant="h4" gutterBottom className={classes.text2} align="left">
                                    Urheberrecht
                                </Typography>
                                <Typography variant="body1" gutterBottom className={classes.text2} align="left">
                                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                                    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                                    <br/><br/>
                                    Quelle:
                                    https://www.e-recht24.de
                                </Typography>
                            </CardContent>
                        </Container>
                    </Container>
                    <Footer/>
                </main>
            </ThemeProvider>
        </React.Fragment>
    );
}
